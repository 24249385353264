import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, filter, map, of } from 'rxjs';
import { environment } from 'src/environment';
import { extService } from 'src/models/extService';
import { NotificationBar } from 'src/services/notification.service';
import { authNeeded } from '../app-routing.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  profilePicture = "";
  username = "";
  isSignedIn = false;
  isAuthUrl = false;
  isDark = false;
  isAdmin = false;
  isSupport = false;
  isPartner = false;
  isPremium = false;
  notificationBar: NotificationBar;
  extServices = Array<extService>();
  isLoading : Observable<boolean> = of(true);

  constructor(private router: Router, private fireAuth: AngularFireAuth, private db: AngularFireDatabase, private snackBar: MatSnackBar) {
    this.notificationBar = new NotificationBar(snackBar);
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isAuthUrl = event.url.startsWith("/auth");
      }
    });
  }
   
  ngOnInit() {
    this.isLoading = this.router.events.pipe(filter( event =>
      event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError
    ),map( event => event instanceof NavigationStart));
    this.fireAuth.onAuthStateChanged(() => {
      this.checkIfSignedIn();
    });
    this.loadThemePreference();
    this.loadExtServices();
  }

  async loadExtServices() {
    var dbRef = this.db.database.ref('/services');
    await dbRef.once('value').then( x => {
      x.forEach( x => {
        var extService = x.toJSON() as extService;
        this.extServices.push(extService);
      })
    })
    this.extServices.sort((a, b) => a.position - b.position);
  }

  loadThemePreference() {
    var loadedTheme = localStorage.getItem('theme');
    if (loadedTheme == null) {
      this.checkThemePreference();
    }
    else if (loadedTheme == "dark") {
      this.toggleDarkTheme();
    }
  }

  checkThemePreference() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.toggleDarkTheme();
    }
    else {
      localStorage.setItem('theme', 'light');
    }
  }

  toggleDarkTheme() {
    if (this.isDark) {
      document.body.classList.remove('dark-mode');
      this.isDark = false;
      localStorage.setItem('theme', 'light');
    } 
    else {
      document.body.classList.add('dark-mode');
      this.isDark = true;
      localStorage.setItem('theme', 'dark');
    }
  }

  async checkIfSignedIn() {
    var user = await (this.fireAuth.currentUser);
    if (user && !this.isAuthUrl) {
      this.isSignedIn = true;
      this.profilePicture = user.photoURL;
      this.username = user.displayName;
      user.getIdTokenResult().then( idTokenResult => {
        this.isAdmin = idTokenResult.claims["admin"];
        this.isSupport = idTokenResult.claims["support"];
        this.isPartner = idTokenResult.claims["partner"];
        this.isPremium = idTokenResult.claims["premium"];
      });
    } 
    else {
      this.isSignedIn = false;
      this.isAdmin = false;
      this.isSupport = false;
      this.isPartner = false;
      this.isPremium = false;
    }
  }

  signIn() {
    var currentPage = window.location.href;
    window.location.href = environment.authUrl + currentPage;
  }
  
  signOut() {
    var currentPath = window.location.pathname;
    if (authNeeded.some(route => currentPath.includes(route))) {
      this.router.navigate(['']);
    } 
    this.fireAuth.signOut().then( () => {  
      this.notificationBar.show("Signed out!")
    }, err => {
      this.notificationBar.showUnknownError()
    })
  }
}
