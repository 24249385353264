export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyBGXCPg4b7Ky-djslH6lWM8h4dp3hfmj5o",
    authDomain: "liumex.firebaseapp.com",
    projectId: "liumex",
    storageBucket: "assets.liumex.com",
    databaseURL: "https://liumex-default-rtdb.europe-west1.firebasedatabase.app/",
    messagingSenderId: "472208918985",
    appId: "1:472208918985:web:c8943c5f0d06a5cd514018",
  },
  recaptchaKey: "6LcNnc0oAAAAANK6m4qYqmien7gdCbgvIfQZnmQB",
  notificationDuration: 3500,
  assetDomain: "https://assets.liumex.com/",
  authUrl: "https://account.liumex.com/auth?redirectTo="
};