import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AccountComponent } from './account/account.component';
import { LiumexAuthGuard } from 'src/services/liumex-auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [LiumexAuthGuard],
  },
  {
    path: '',
    component: AccountComponent,
    canActivate: [LiumexAuthGuard],
  },
  { 
    path: '**', 
    redirectTo: '/auth',
  }
];

export const authNeeded = [
  "/",
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
