<mat-card>
    <mat-card-header>
      <mat-card-title>Re-Authentication</mat-card-title>
    </mat-card-header>
    <form [formGroup]="authenticationGroup" (ngSubmit)="authenticate()">
        <mat-card-content>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>{{ data.email }} Email Address</mat-label>
                <input tabIndex="-1" matInput type="email" [formControl]="authenticationGroup.controls.emailControl" readonly>
            </mat-form-field>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>{{ data.password }} Password</mat-label>
                <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="authenticationGroup.controls.passwordControl" autocomplete="current-password">
                <button mat-icon-button matSuffix type="button" (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <mat-card-actions>
                <button mat-raised-button type="submit" *ngIf="!isProcessing" [disabled]="(authenticationGroup.controls.emailControl.value == '' || authenticationGroup.controls.passwordControl.value == '') || !authenticationGroup.valid" color="accent">Authenticate</button>
                <button mat-raised-button type="submit" *ngIf="isProcessing" disabled><mat-spinner></mat-spinner></button>
            </mat-card-actions>
        </mat-card-content>
    </form>
</mat-card>   