<mat-card>
  <mat-toolbar color="primary">
    <h1>LIUMEX</h1>
  </mat-toolbar>
  <mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)" color="primary">
    <mat-tab *ngIf="!waitingOnVerify && !isVerifyEmail && !isRecoverEmail && !isResetPassword" label="Sign In">
      <form [formGroup]="signinGroup" (ngSubmit)="signin()">
        <mat-card-content>
          <mat-form-field appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Enter your email</mat-label>
            <input matInput type="email" [formControl]="signinGroup.controls.emailControl" autocomplete="username">
            <mat-error *ngIf="signinGroup.controls.emailControl.invalid">{{getErrorEmailLogin()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Enter your password</mat-label>
            <input matInput [type]="hidePasswordSignin ? 'password' : 'text'" [formControl]="signinGroup.controls.passwordControl" autocomplete="current-password">
            <button mat-icon-button matSuffix type="button" (click)="hidePasswordSignin = !hidePasswordSignin" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordSignin">
              <mat-icon>{{hidePasswordSignin ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <p class="forgot-password" (click)="forgotPassword()" *ngIf="!sendingPasswordReset">Forgot Password?</p>       
          <p class="forgot-password" *ngIf="sendingPasswordReset"><mat-spinner></mat-spinner></p>     
          <mat-card-actions>
            <button mat-raised-button type="submit" *ngIf="!isProcessing" [disabled]="(signinGroup.controls.emailControl.value == '' || signinGroup.controls.passwordControl.value == '') || !signinGroup.valid" color="accent">Sign In</button>
            <button mat-raised-button type="submit" *ngIf="isProcessing" disabled><mat-spinner></mat-spinner></button>
          </mat-card-actions>
          <p class="recaptcha-info">This site is protected by reCAPTCHA and the Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        </mat-card-content>
      </form>
    </mat-tab>
    <mat-tab *ngIf="!waitingOnVerify && !isVerifyEmail && !isRecoverEmail && !isResetPassword" label="Sign Up"> 
      <form [formGroup]="signupGroup" (ngSubmit)="signup()">
        <mat-card-content>
          <mat-form-field appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Nickname</mat-label>
            <input matInput type="text" [formControl]="signupGroup.controls.usernameControl">
            <mat-error *ngIf="signupGroup.controls.usernameControl.invalid">{{getErrorUsernameSignup()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Email</mat-label>
            <input matInput type="email" [formControl]="signupGroup.controls.emailControl">
            <mat-error *ngIf="signupGroup.controls.emailControl.invalid">{{getErrorEmailSignup()}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" [hideRequiredMarker]="true">
            <mat-label>Password</mat-label>
            <input matInput [type]="hidePasswordSignup ? 'password' : 'text'" [formControl]="signupGroup.controls.passwordControl" autocomplete="new-password">
            <button mat-icon-button matSuffix type="button" (click)="hidePasswordSignup = !hidePasswordSignup" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordSignup">
              <mat-icon>{{hidePasswordSignup ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="signupGroup.controls.passwordControl.invalid">{{getErrorPasswordSignup()}}</mat-error>
          </mat-form-field>
          <div class="consent-form-field">
            <mat-checkbox [formControl]="signupGroup.controls.consentControl">I agree to the <a target="_blank" href="https://liumex.comterms">Terms of Service</a> and <a target="_blank" href="https://liumex.com/privacy">Privacy Policy</a>.</mat-checkbox>
          </div> 
          <mat-card-actions>
            <button mat-raised-button type="submit" *ngIf="!isProcessing" [disabled]="(signupGroup.controls.usernameControl.value == '' || signupGroup.controls.emailControl.value == '' || signupGroup.controls.passwordControl.value == '') || !signupGroup.valid" color="accent">Sign Up</button>
            <button mat-raised-button type="submit" *ngIf="isProcessing" disabled><mat-spinner></mat-spinner></button>
          </mat-card-actions>
          <p class="recaptcha-info">This site is protected by reCAPTCHA and the Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        </mat-card-content>
      </form>
    </mat-tab>
    <mat-tab *ngIf="waitingOnVerify && !isVerifyEmail && !isRecoverEmail && !isResetPassword" label="Email Verification Pending">
        <mat-card-content>
          <p class="verify-email">{{ emailForVerify }}</p>
          <mat-spinner class="verify-spinner"></mat-spinner>
          <button mat-raised-button class="verify-button" *ngIf="!lockResend" color="accent" (click)="resendVerificationEmail()">Resend</button>
          <button mat-raised-button class="verify-button" *ngIf="lockResend" disabled color="accent">Resend ({{ lockTime }})</button>
        </mat-card-content>
    </mat-tab>
    <mat-tab *ngIf="!waitingOnVerify && isVerifyEmail && !isRecoverEmail && !isResetPassword" label="Email Verification">
      <mat-card-content>
        <mat-spinner *ngIf="isProcessing" class="verify-spinner"></mat-spinner>
        <p class="info-text" *ngIf="wasSuccessful && !isProcessing">Email verified, you can close this window now!</p>
        <p class="info-text" *ngIf="!wasSuccessful && !isProcessing">Failed</p>
      </mat-card-content>
    </mat-tab>
    <mat-tab *ngIf="!waitingOnVerify && !isVerifyEmail && isRecoverEmail && !isResetPassword" label="Recover Email">
      <mat-card-content>
        <mat-spinner *ngIf="isProcessing" class="verify-spinner"></mat-spinner>
        <p class="info-text" *ngIf="wasSuccessful && !isProcessing">Email recovered, you can close this window now!</p>
        <p class="info-text" *ngIf="!wasSuccessful && !isProcessing">Failed</p>
      </mat-card-content>
    </mat-tab>
    <mat-tab *ngIf="!waitingOnVerify && !isVerifyEmail && !isRecoverEmail && isResetPassword" label="Reset Password">
      <form [formGroup]="resetPasswordGroup" (ngSubmit)="resetPassword()">
        <mat-card-content>
          <mat-form-field *ngIf="!wasSuccessful" appearance="fill" [hideRequiredMarker]="true">
            <mat-label>New Password</mat-label>
            <input matInput [type]="hidePasswordReset ? 'password' : 'text'" [formControl]="resetPasswordGroup.controls.passwordControl">
            <button mat-icon-button matSuffix type="button" (click)="hidePasswordReset = !hidePasswordReset" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordReset">
              <mat-icon>{{hidePasswordReset ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="resetPasswordGroup.controls.passwordControl.invalid">{{getErrorPasswordReset()}}</mat-error>
          </mat-form-field>
          <mat-card-actions *ngIf="!wasSuccessful">
            <button mat-raised-button type="submit" *ngIf="!isProcessing" [disabled]="resetPasswordGroup.controls.passwordControl.value == '' || !resetPasswordGroup.valid" color="accent">Set Password</button>
            <button mat-raised-button type="submit" *ngIf="isProcessing" disabled><mat-spinner></mat-spinner></button>
          </mat-card-actions>
          <p class="info-text" *ngIf="wasSuccessful && !isProcessing">You can close this window now!</p>
          <p class="recaptcha-info" *ngIf="!wasSuccessful">This site is protected by reCAPTCHA and the Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        </mat-card-content>
      </form>
    </mat-tab>
  </mat-tab-group>
</mat-card>