import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { User } from 'firebase/auth';
import { authNeeded } from 'src/app/app-routing.module';
import { environment } from 'src/environment';


@Injectable({
  providedIn: 'root'
})
export class LiumexAuthGuard {
    userRoles: string[] = [];

    constructor(private fireAuth: AngularFireAuth, private router: Router) { }

    async canActivate(activatedRoute: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.fireAuth.onAuthStateChanged(async (user) => {
                var jwt = activatedRoute.queryParamMap.get('jwt')
                if (user && user.emailVerified == true) {
                    var hasPermission = await this.checkUserPermission(activatedRoute, user);
                    if (hasPermission == false) {
                        this.router.navigate([''])
                    }
                    if (jwt) {
                        window.location.href = window.location.href.split("?")[0];
                        resolve(true);
                    }
                    resolve(true);
                } 
                else if (user && user.emailVerified == false) {
                    var currentPage = window.location.href;
                    if (activatedRoute.routeConfig.path != "auth") {
                        window.location.href = environment.authUrl + currentPage;
                        resolve(false);
                    } 
                    else {
                        resolve(true);
                    }
                }
                else {
                    if (jwt) {
                        await this.fireAuth.signInWithCustomToken(jwt).then( async res => {
                            resolve(true);
                          }, err => {
                            var currentPage = window.location.href;
                            window.location.href = environment.authUrl + currentPage;
                            resolve(false);
                        })
                    }
                    else {
                        var currentPath = window.location.pathname;
                        if (authNeeded.some(route => currentPath.includes(route)) && !currentPath.includes("auth")) {  
                            var currentPage = window.location.href;
                            window.location.href = environment.authUrl + currentPage;
                            resolve(false);
                        } 
                        else {
                            resolve(true);
                        }
                    }
                }
            });            
        });
    }

    async checkUserPermission(activatedRoute: ActivatedRouteSnapshot, user: User): Promise<boolean> {
        await user.getIdTokenResult().then( x => {
            if (x.claims["admin"] == true) {
                this.userRoles.push("admin") 
            }
            if (x.claims["support"] == true) {
                this.userRoles.push("support") 
            }
            if (x.claims["partner"] == true) {
                this.userRoles.push("partner") 
            }
            if (x.claims["premium"] == true) {
                this.userRoles.push("premium") 
            }
        })
        if (activatedRoute.data['roles']) {
            var hasPermission = false;
            this.userRoles.forEach( userRole => {
                if (activatedRoute.data['roles'].indexOf(userRole) != -1) {
                    hasPermission = true;
                }
            })
            return hasPermission;
        }
        else {
            return true;
        }
    }
}