import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { User } from 'firebase/auth';
import { ReauthenticateDialog } from '../reauthenticate/reauthenticate.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ChoosePictureDialog } from '../choose-picture/choose-picture.component';
import { Subscription, interval } from 'rxjs';
import { NotificationBar } from 'src/services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  hidePassword = true;
  userData: User;
  isProcessingUsername = false;
  isProcessingEmail = false;
  isProcessingPassword = false;
  isProcessingDelete = false;
  profilePicture = "";
  userID = "";
  emailChangeSubscription: Subscription;
  notificationBar: NotificationBar;

  
  usernameGroup = new FormGroup({
    usernameControl: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(16)])
  });

  emailGroup = new FormGroup({
    emailControl: new FormControl('', [Validators.required, Validators.email])
  });

  passwordGroup = new FormGroup({
    passwordControl: new FormControl('', [Validators.minLength(8)])
  });

  deleteGroup = new FormGroup({
    deleteControl: new FormControl('', [(x) => { if (x.value != "DELETE" && x.value != "") { return { wrong: true };} return null;}])
  });

  constructor(private router: Router, private title: Title, private fireAuth: AngularFireAuth, private fireFunctions: AngularFireFunctions,
    public dialog: MatDialog, private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar) {
      this.notificationBar = new NotificationBar(snackBar);
      var scripts = document.getElementsByTagName('script');
      for(var i=0;i<scripts.length;i++){
        if (scripts[i].src.includes("recaptcha")) {
          scripts[i].setAttribute('data-cookie-consent', 'strictly-necessary');
        }        
      }
  }

  async ngOnInit() {
    this.title.setTitle("Account - LIUMEX");
    await this.fireAuth.currentUser.then(res => {
      this.userData = res;
    })
    this.profilePicture = this.userData.photoURL;
    this.userID = this.userData.uid;
    this.usernameGroup.controls.usernameControl.setValue(this.userData.displayName);
    this.emailGroup.controls.emailControl.setValue(this.userData.email); 
  }

  getErrorUsernameChange() {
    if (this.usernameGroup.controls.usernameControl.hasError('maxlength')) {
      return 'Maximum 16 characters'
    }
    else return this.usernameGroup.controls.usernameControl.hasError('minlength') ? 'Use 4 characters or more' : '';
  }  

  getErrorEmailChange() {
    return this.emailGroup.controls.emailControl.hasError('email') ? 'Not a valid email' : '';
  }

  getErrorPasswordChange() {
    return this.passwordGroup.controls.passwordControl.hasError('minlength') ? 'Use 8 characters or more' : '';
  }

  changeProfilePicture() {
    var dialogRef = this.dialog.open(ChoosePictureDialog);
    return dialogRef.afterClosed().toPromise().then(res => {
      if (res != true) {
        this.notificationBar.show("Canceled!")
      }
      else {
        this.userData.reload();
        this.profilePicture = this.userData.photoURL;
      }
    });
  }

  async changeUsername() {
    if (this.usernameGroup.valid == false) {
      return;
    }
    this.recaptchaV3Service.execute('changeUsername').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        this.isProcessingUsername = true;
        (await this.fireAuth.currentUser).updateProfile({
          displayName: this.usernameGroup.controls.usernameControl.value
        }).then( res => {
          this.isProcessingUsername = false;
          this.userData.reload();
          this.notificationBar.show("Username updated!");
        }, err => {
          this.isProcessingUsername = false;
          this.notificationBar.showUnknownError();
        })
      }
    });
  }

  async changeEmail() {
    if (this.emailGroup.valid == false) {
      return;
    }
    this.recaptchaV3Service.execute('changeEmail').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        if (await this.reauthenticate(true, false) == true) {
          (await this.fireAuth.currentUser).verifyBeforeUpdateEmail(this.emailGroup.controls.emailControl.value).then( res => {
            this.isProcessingEmail = false;
            this.notificationBar.show("Verification email sent!");
            this.emailGroup.controls.emailControl.setValue("Verification Pending");
            this.emailGroup.disable();
            var tickRate = interval(2000);
            this.emailChangeSubscription = tickRate.subscribe(async tick => {
              (await this.fireAuth.currentUser).reload().catch(() => {
                this.router.navigate(['/auth']);
              });
            })
          }, err => {
            this.isProcessingEmail = false;
            this.notificationBar.showUnknownError();
            if (err.code === 'auth/email-already-in-use') {
              this.notificationBar.show("Email already connected to an account!");
            }
            else {
              this.notificationBar.showUnknownError();
            };
          });
        };
      }
    })
  }

  async changePassword() {
    if (this.passwordGroup.valid == false) {
      return;
    }
    this.recaptchaV3Service.execute('changePassword').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        if (await this.reauthenticate(false, true) == true) {
          (await this.fireAuth.currentUser).updatePassword(this.passwordGroup.controls.passwordControl.value).then( res => {
            this.isProcessingPassword = false;
            this.userData.reload();
            this.notificationBar.show("Password updated!");
          }, err => {
            this.isProcessingPassword = false;
            if (err.code == 'auth/weak-password') {
              this.notificationBar.show("Password is too weak!");
              this.passwordGroup.controls.passwordControl.setValue("");
            } 
            else {
              this.notificationBar.showUnknownError();
            }
          })
        }
        this.passwordGroup.reset();
        this.passwordGroup.controls.passwordControl.setErrors(null)
        this.passwordGroup.controls.passwordControl.setValue("");
        this.hidePassword = true;
      }
    })
  }

  async signOutEverywhere() { 
    var idToken = "";
    (await this.fireAuth.currentUser).getIdToken(true).then( res => {
      idToken = res;
      this.fireFunctions.httpsCallable('auth-revokeAllTokens')(idToken).toPromise().then( x => {
        // ! should get an error since user is already signed out !
      }).catch( err => {
        this.fireAuth.signOut().then( () => {
          window.location.reload();
          this.notificationBar.show("Signed out everywhere!")
        }, err => {
          this.notificationBar.showUnknownError()
        })
      });
    });
  }

  deleteAccount() {
    if (this.deleteGroup.valid == false) {
      return;
    }
    this.recaptchaV3Service.execute('deleteAcc').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        if (await this.reauthenticate(false, false) == true) {
          (await this.fireAuth.currentUser).delete().then( res => {
            this.isProcessingDelete = false;
            this.notificationBar.show("Account deleted!");
            this.router.navigate(['auth'])
          }, err => {
            this.isProcessingDelete = false;
            this.notificationBar.showUnknownError();
          })
        }
        this.deleteGroup.reset();
        this.deleteGroup.controls.deleteControl.setErrors(null);
        this.deleteGroup.controls.deleteControl.setValue("");
      }
    })
  }

  async reauthenticate(email: boolean, password: boolean) {
    var emailInfo = "";
    if (email == true) {
      emailInfo = "Old";
    }
    var passwordInfo = "";
    if (password == true) {
      passwordInfo = "Old";
    }
    var dialogRef = this.dialog.open(ReauthenticateDialog, {
      data: {
        email: emailInfo,
        password: passwordInfo
      }
    });
    return dialogRef.afterClosed().toPromise().then(res => {
      if (res != true) {
        this.notificationBar.show("Canceled!")
        return false;
      }
      else {
        return true;
      }
    });
  }
}
