<mat-card>
    <mat-card-header>
        <mat-card-title>Profile Picture</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <img src="{{ profilePicture }}">
        <mat-card-actions>
            <button mat-raised-button color="accent" (click)="changeProfilePicture()">Change</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
<br>
<mat-card>
    <mat-card-header>
        <mat-card-title>Nickname</mat-card-title>
    </mat-card-header>
    <form [formGroup]="usernameGroup" (ngSubmit)="changeUsername()">
        <mat-card-content>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>Nickname</mat-label>
                <input matInput type="text" [formControl]="usernameGroup.controls.usernameControl">
                <mat-error *ngIf="usernameGroup.controls.usernameControl.invalid">{{getErrorUsernameChange()}}</mat-error>
            </mat-form-field>
            <mat-card-actions>
                <button mat-raised-button type="submit" *ngIf="!isProcessingUsername" [disabled]="!usernameGroup.valid || usernameGroup.controls.usernameControl.value == userData.displayName" color="accent">Save Changes</button>
                <button mat-raised-button type="submit" *ngIf="isProcessingUsername" disabled><mat-spinner></mat-spinner></button>
            </mat-card-actions>
        </mat-card-content>
    </form>
    </mat-card>
    <br>
    <mat-card>
    <mat-card-header>
        <mat-card-title>Email</mat-card-title>
    </mat-card-header>
    <form [formGroup]="emailGroup" (ngSubmit)="changeEmail()">
        <mat-card-content>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>Email Address</mat-label>
                <input matInput type="email" [formControl]="emailGroup.controls.emailControl">
                <mat-error *ngIf="emailGroup.controls.emailControl.invalid">{{getErrorEmailChange()}}</mat-error>
            </mat-form-field>
            <mat-card-actions>
                <button mat-raised-button type="submit" *ngIf="!isProcessingEmail" [disabled]="!emailGroup.valid || emailGroup.controls.emailControl.value == userData.email" color="accent">Save and Verify</button>
                <button mat-raised-button type="submit" *ngIf="isProcessingEmail" disabled><mat-spinner></mat-spinner></button>
            </mat-card-actions>
        </mat-card-content>
    </form>
    </mat-card>
    <br>
    <mat-card>
    <mat-card-header>
        <mat-card-title>Password</mat-card-title>
    </mat-card-header>
    <form [formGroup]="passwordGroup" (ngSubmit)="changePassword()">
        <mat-card-content>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>New password</mat-label>
                <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="passwordGroup.controls.passwordControl" autocomplete="new-password">
                <button mat-icon-button matSuffix type="button" (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="passwordGroup.controls.passwordControl.invalid">{{getErrorPasswordChange()}}</mat-error>
            </mat-form-field>
            <mat-card-actions>
                <button mat-raised-button type="submit" [disabled]="passwordGroup.controls.passwordControl.value == '' || !passwordGroup.valid" color="accent">Save Changes</button>
            </mat-card-actions>
        </mat-card-content>
    </form>
    </mat-card>
    <br>
    <!-- <mat-card>
    <mat-card-header>
        <mat-card-title>Organizations</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="accent">Leave</button>
        </mat-card-actions>
    </mat-card-content>
    </mat-card>
    <br> -->
    <mat-card>
    <mat-card-header>
        <mat-card-title>Force Sign Out</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p class="force-sign-out"> Worried that your account or password has been compromised? Sign out from all our services on all devices!</p>
        <mat-card-actions>
            <button mat-raised-button (click)="signOutEverywhere()" color="accent">Sign Out Everywhere</button>
        </mat-card-actions>
    </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
    <mat-card-header>
        <mat-card-title>Delete Account</mat-card-title>
    </mat-card-header>
    <form [formGroup]="deleteGroup" (ngSubmit)="deleteAccount()">
        <mat-card-content>
            <mat-form-field appearance="fill" [hideRequiredMarker]="true">
                <mat-label>Enter "DELETE"</mat-label>
                <input matInput type="text" [formControl]="deleteGroup.controls.deleteControl">
            </mat-form-field>
            <mat-card-actions>
                <button mat-raised-button type="submit" [disabled]="deleteGroup.controls.deleteControl.value == '' || !deleteGroup.valid" color="accent">Delete</button>
            </mat-card-actions>
        </mat-card-content>
    </form>
    </mat-card>
    <p>User ID: {{ userID }}</p>
    <p class="recaptcha-info">This site is protected by reCAPTCHA and the Google <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>