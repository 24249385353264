<mat-card>
    <mat-card-header>
    <mat-card-title>Choose Profile Picture</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="picture-frame">
            <div class="pictures-loading" *ngIf="this.profilePictures.length == 0">
                <mat-spinner></mat-spinner>
            </div>                
            <ng-container *ngFor="let picture of profilePictures">
                <div class="pictures" (click)="select(picture)">
                    <img [ngClass]="{ 'selected': selectedPicture == picture.link }" src="{{ picture.link }}">
                </div>
            </ng-container>
        </div>
        <mat-card-actions>
            <button mat-raised-button type="submit" *ngIf="!isProcessing" (click)="save()" tabindex="-1" color="accent">Save</button>
            <button mat-raised-button type="submit" *ngIf="isProcessing" disabled><mat-spinner></mat-spinner></button>
        </mat-card-actions>
    </mat-card-content>
</mat-card> 