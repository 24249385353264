import { Component, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailAuthProvider, User } from 'firebase/auth';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NotificationBar } from 'src/services/notification.service';

@Component({
  selector: 'app-reauthenticate',
  templateUrl: './reauthenticate.component.html',
  styleUrls: ['./reauthenticate.component.scss']
})
export class ReauthenticateDialog {
  hidePassword = true;
  userData: User;
  isProcessing = false;
  notificationBar: NotificationBar;

  authenticationGroup = new FormGroup({
    emailControl: new FormControl('', [ Validators.email]),
    passwordControl: new FormControl('', [])
  });

  constructor(public dialogRef: MatDialogRef<ReauthenticateDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private fireauth: AngularFireAuth, 
  private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar) {
    this.notificationBar = new NotificationBar(snackBar)
  }

  async ngOnInit() {
    await this.fireauth.currentUser.then(res => {
      this.userData = res;
    })
    this.authenticationGroup.controls.emailControl.setValue(this.userData.email);
  }

  async authenticate() {
    var user = (await this.fireauth.currentUser);
    var credential = EmailAuthProvider.credential(
        user.email, 
        this.authenticationGroup.controls.passwordControl.value
    );
    this.recaptchaV3Service.execute('reAuth').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        this.isProcessing = true;
        user.reauthenticateWithCredential(credential).then(res => {
          this.isProcessing = false;
          this.dialogRef.close(true)
        }, err => {
          this.isProcessing = false;          
          if (err.code === 'auth/user-not-found') {
            this.notificationBar.show("User not found!");
            this.authenticationGroup.reset()
          } 
          else if (err.code === 'auth/wrong-password') {
            this.notificationBar.show("Wrong password!");
            this.authenticationGroup.controls.passwordControl.reset();
          }
          else {
            this.notificationBar.showUnknownError();
            this.authenticationGroup.reset()
          }
        });
      }
    });
  }
}