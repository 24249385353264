import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'firebase/auth';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environment';
import { StorageFile } from 'src/models/storageFile';
import { NotificationBar } from 'src/services/notification.service';

@Component({
  selector: 'app-choose-picture',
  templateUrl: './choose-picture.component.html',
  styleUrls: ['./choose-picture.component.scss']
})
export class ChoosePictureDialog {
  userData: User;
  profilePictures = new Array<StorageFile>();
  isProcessing = false;
  selectedPicture = "";
  notificationBar: NotificationBar;
  
  constructor(public dialogRef: MatDialogRef<ChoosePictureDialog>, private fireauth: AngularFireAuth, private fireStorage: AngularFireStorage, 
    private recaptchaV3Service: ReCaptchaV3Service, private snackBar: MatSnackBar) {
      this.notificationBar = new NotificationBar(snackBar)
     }

  async ngOnInit() {
    await this.fireauth.currentUser.then(res => {
      this.userData = res;
    })
    await this.getStorageFiles("profile_images").then(storageFiles => {
      this.profilePictures = storageFiles;
      this.profilePictures.sort(function(a, b) {
        return a.name.localeCompare(b.name);
     });
    });
    this.selectedPicture = this.userData.photoURL;
  }

  getStorageFiles(location: string): Promise<StorageFile[]> {
    return new Promise((resolve, reject) => {
      const storageFiles: StorageFile[] = [];
      this.fireStorage.ref(location).listAll().subscribe(files => {
        const downloadPromises = files.items.map(file => {
          const storageFile = new StorageFile();
          storageFile.name = file.name;
          storageFile.path = file.fullPath;
          storageFile.link = environment.assetDomain + file.fullPath;
          return file.getMetadata().then(data => {
            storageFile.size = data.size;
            storageFile.created = data.timeCreated;
            storageFile.updated = data.updated;
            storageFiles.push(storageFile);
          });
        });
        Promise.all(downloadPromises).then(() => {
          resolve(storageFiles);
        }).catch(error => {
          reject(error);
        });
      });
    });
  }

  select(picture: StorageFile) {
    this.selectedPicture = picture.link;
  }

  async save() {
    this.recaptchaV3Service.execute('changeProfilePicture').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        this.isProcessing = true;
        (await this.fireauth.currentUser).updateProfile({
          photoURL: this.selectedPicture
        }).then( res => {
          this.isProcessing = false;
          this.notificationBar.show("Profile picture updated!");
          this.dialogRef.close(true)
        }, err => {
          this.isProcessing = false;
          this.notificationBar.showUnknownError();
        })
      }
    });
  }
}
