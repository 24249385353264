import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription, interval } from 'rxjs';
import { environment } from 'src/environment';
import { StorageFile } from 'src/models/storageFile';
import { SignUpUser, SignInUser } from 'src/models/user';
import { NotificationBar } from 'src/services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  selectedTab = new FormControl(0);
  waitingOnVerify = false;
  emailForVerify: string;
  isProcessing = false;
  sendingPasswordReset = false;
  hidePasswordSignin = true;
  hidePasswordSignup = true;
  hidePasswordReset = true;
  redirectPage: string;
  verificationCheckSubscription: Subscription;
  resendLockCheckSubscription: Subscription;
  lockTime = 60;
  lockResend = true;
  storageFiles = new Array<StorageFile>();
  firstInitCall = true;
  isVerifyEmail = false;
  isRecoverEmail = false;
  isResetPassword = false;
  wasSuccessful = false;
  actionMode: string;
  oobCode: string;
  apiKeyFireUrl: string;
  authToken: string;
  notificationBar: NotificationBar;

  signinGroup = new FormGroup({
    emailControl: new FormControl('', [Validators.email]),
    passwordControl: new FormControl('', [])
  });

  signupGroup = new FormGroup({
    usernameControl: new FormControl('', [Validators.minLength(4), Validators.maxLength(16)]),
    emailControl: new FormControl('', [Validators.email,]),
    passwordControl: new FormControl('', [Validators.minLength(8)]),
    consentControl: new FormControl(false, [Validators.requiredTrue])
  });

  resetPasswordGroup = new FormGroup({
    passwordControl: new FormControl('', [Validators.minLength(8)])
  });

  constructor(private activeRoute: ActivatedRoute, private router: Router, private fireAuth: AngularFireAuth,
    private recaptchaV3Service: ReCaptchaV3Service, private title: Title, private fireStorage: AngularFireStorage, 
    private fireFunctions: AngularFireFunctions, private snackBar: MatSnackBar) {
      this.notificationBar = new NotificationBar(snackBar);
      var scripts = document.getElementsByTagName('script');
      for(var i=0;i<scripts.length;i++){
        if (scripts[i].src.includes("recaptcha")) {
          scripts[i].setAttribute('data-cookie-consent', 'strictly-necessary');
        }        
      }
  }

  async ngOnInit() {
    if (this.firstInitCall == true) {     
      this.redirectPage = this.activeRoute.snapshot.queryParamMap.get('redirectTo');
      this.actionMode = this.activeRoute.snapshot.queryParamMap.get('mode');
      this.oobCode = this.activeRoute.snapshot.queryParamMap.get('oobCode');
      this.apiKeyFireUrl = this.activeRoute.snapshot.queryParamMap.get('apiKey');
      if (this.actionMode == null) {
        this.title.setTitle("Authenticate - LIUMEX");
      }
      else if (this.actionMode == "verifyEmail" || this.actionMode == "verifyAndChangeEmail") {
        this.title.setTitle("Verify Email | LIUMEX"); 
        this.isVerifyEmail = true;
        this.verifyEmail();
      }
      else if (this.actionMode == "recoverEmail") {
        this.title.setTitle("Recover Email | LIUMEX"); 
        this.isRecoverEmail = true;
        this.recoverEmail();
      }
      else if (this.actionMode == "resetPassword") {
        this.title.setTitle("Reset Password | LIUMEX"); 
        this.isResetPassword = true;
      }
      this.firstInitCall = false;
    }
  }
  
  getStorageFiles(location: string): Promise<StorageFile[]> {
    return new Promise((resolve, reject) => {
      const storageFiles: StorageFile[] = [];
      this.fireStorage.ref(location).listAll().subscribe(files => {
        const downloadPromises = files.items.map(file => {
          const storageFile = new StorageFile();
          storageFile.name = file.name;
          storageFile.path = file.fullPath;
          storageFile.link = environment.assetDomain + file.fullPath;
          return file.getMetadata().then(data => {
            storageFile.size = data.size;
            storageFile.created = data.timeCreated;
            storageFile.updated = data.updated;
            storageFiles.push(storageFile);
          });
        });
        Promise.all(downloadPromises).then(() => {
          resolve(storageFiles);
        }).catch(error => {
          reject(error);
        });
      });
    });
  }

  verifyEmail() {
    this.isProcessing = true;
    this.fireAuth.applyActionCode(this.oobCode).then( res => {
      this.isProcessing = false;
      this.wasSuccessful = true;
    }, err => {
      this.isProcessing = false;
      this.wasSuccessful = false;
      if (err.code === 'auth/expired-action-code') {
        this.notificationBar.show("Verification link expired!");
      } 
      else if (err.code === 'auth/invalid-action-code') {
        this.notificationBar.show("Verification link invalid!");
      }
      else if (err.code === 'auth/user-disabled') {
        this.notificationBar.show("Account is disabled, please contact the support!");
      }
      else if (err.code === 'auth/user-not-found') {
        this.notificationBar.show("Account not found!");
      }
      else {
        this.notificationBar.showUnknownError();            
      }
    })
  }

  recoverEmail() {
    this.isProcessing = true;
    this.fireAuth.applyActionCode(this.oobCode).then( res => {
      this.isProcessing = false;
      this.wasSuccessful = true;
    }, err => {
      this.isProcessing = false;
      this.wasSuccessful = false;
      if (err.code === 'auth/expired-action-code') {
        this.notificationBar.show("Recovery link expired!");
      } 
      else if (err.code === 'auth/invalid-action-code') {
        this.notificationBar.show("Recovery link invalid!");
      }
      else if (err.code === 'auth/user-disabled') {
        this.notificationBar.show("Account is disabled, please contact the support!");
      }
      else if (err.code === 'auth/user-not-found') {
        this.notificationBar.show("Account not found!");
      }
      else {
        this.notificationBar.showUnknownError();            
      }
    })
  }

  resetPassword() {
    this.isProcessing = true;
    var newPassword = this.resetPasswordGroup.controls.passwordControl.value;
    this.recaptchaV3Service.execute('setResetPassword').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        this.fireAuth.confirmPasswordReset(this.oobCode, newPassword).then( res => {
          this.isProcessing = false;
          this.notificationBar.show("Password updated!");
          this.resetPasswordGroup.reset();
          this.resetPasswordGroup.controls.passwordControl.setErrors(null)
          this.hidePasswordReset = true;
          this.wasSuccessful = true;
        }, err => {
          this.isProcessing = false;
          if (err.code === 'auth/expired-action-code') {
            this.notificationBar.show("Password reset link expired!");
          } 
          else if (err.code === 'auth/invalid-action-code') {
            this.notificationBar.show("Password reset link invalid!");
          }
          else if (err.code === 'auth/user-disabled') {
            this.notificationBar.show("Account is disabled, please contact the support!");
          }
          else if (err.code === 'auth/user-not-found') {
            this.notificationBar.show("Account not found!");
          }
          else if (err.code == 'auth/weak-password') {
            this.notificationBar.show("Password is too weak!");
            this.resetPasswordGroup.controls.passwordControl.setValue("");
          } 
          else {
            this.notificationBar.showUnknownError();            
          }
        })
      }
    });
  }

  async redirectAfterAuth(idToken: string) {
    await this.getAuthToken(idToken);
    if (this.authToken) {
      if (this.redirectPage == null || this.redirectPage == "/") {
        window.location.href = window.location.origin;
      }
      else {
        window.location.href = this.redirectPage + "?jwt=" + this.authToken;
      }
    } else {
      this.notificationBar.showUnknownError();
    }
  }

  async getAuthToken(idToken: string) {
    await this.fireFunctions.httpsCallable('auth-getAuthToken')(idToken).toPromise().then( x => {
      this.authToken = x.authToken;
    }).catch( err => {
      this.notificationBar.showUnknownError();
    });
  }
  
  getErrorEmailLogin() {
    return this.signinGroup.controls.emailControl.hasError('email') ? 'Not a valid email' : '';
  }

  getErrorUsernameSignup() {
    if (this.signupGroup.controls.usernameControl.hasError('minlength').valueOf()) {
      return "Use between 4 and 16 characters";
    }
    else if (this.signupGroup.controls.usernameControl.hasError('maxlength').valueOf()) {
      return "Use between 4 and 16 characters";
    }
    return "";
  }

  getErrorEmailSignup() {
    return this.signupGroup.controls.emailControl.hasError('email') ? 'Not a valid email' : '';
  }

  getErrorPasswordSignup() {
    return this.signupGroup.controls.passwordControl.hasError('minlength') ? 'Use 8 characters or more' : '';
  }

  getErrorPasswordReset() {
    return this.resetPasswordGroup.controls.passwordControl.hasError('minlength') ? 'Use 8 characters or more' : '';
  }

  signin() {
    if (this.signinGroup.valid == false) {
      return;
    }
    this.recaptchaV3Service.execute('login').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        this.isProcessing = true;
        var user = new SignInUser();
        user.email = this.signinGroup.controls.emailControl.value;
        user.password = this.signinGroup.controls.passwordControl.value;
        this.fireAuth.signInWithEmailAndPassword(user.email, user.password).then( async res => {
          if (res.user.emailVerified == false) {
            this.checkVerification();
          }
          else {
            var idToken = "";
            await res.user.getIdToken(true).then( res => {
              idToken = res;
            });
            this.redirectAfterAuth(idToken);        
          }
        }, err => {
          this.isProcessing = false;          
          if (err.code === 'auth/user-not-found') {
            this.notificationBar.show("Account not found!");
            this.signinGroup.reset()
            this.signinGroup.controls.emailControl.setErrors(null);
            this.signinGroup.controls.emailControl.setValue("");
            this.signinGroup.controls.passwordControl.setErrors(null);
            this.signinGroup.controls.passwordControl.setValue("");
          } 
          else if (err.code === 'auth/wrong-password') {
            this.notificationBar.show("Wrong password!");
            this.signinGroup.controls.passwordControl.reset();
            this.signinGroup.controls.passwordControl.setValue("");
          }
          else if (err.code === 'auth/user-disabled') {
            this.notificationBar.show("Account is disabled, please contact the support!");
            this.signinGroup.reset()
            this.signinGroup.controls.emailControl.setErrors(null);
            this.signinGroup.controls.emailControl.setValue("");
            this.signinGroup.controls.passwordControl.setErrors(null);
            this.signinGroup.controls.passwordControl.setValue("");
          }
          else if (err.code === 'auth/invalid-email') {
            this.notificationBar.show("Please enter a valid email!");
            this.signinGroup.controls.emailControl.setErrors({ notValid: true });
          }
          else if (err.code === 'auth/missing-password') {
            this.notificationBar.show("Please enter your password!");
            this.signinGroup.controls.passwordControl.setErrors({ passwordMissing: true });
          }
          else {
            this.notificationBar.showUnknownError();            
          }
        })
      }
    });
  }

  forgotPassword() {
    if (this.signinGroup.controls.emailControl.value == "" || this.signinGroup.controls.emailControl.value == null) {
      this.notificationBar.show("Please enter your email!");
      return;
    }
    this.recaptchaV3Service.execute('resetPassword').subscribe(async (token) => {
      if (token == "") {
        return;
      }
      else {
        this.sendingPasswordReset = true;
        this.fireAuth.sendPasswordResetEmail(this.signinGroup.controls.emailControl.value).then(() => {
          this.sendingPasswordReset = false;
          this.notificationBar.show("Password reset email sent!");
          this.signinGroup.controls.passwordControl.reset();
          this.signinGroup.controls.passwordControl.setValue("");
          this.signinGroup.controls.passwordControl.setErrors(null);
        }, err => {  
          this.sendingPasswordReset = false;      
          if (err.code === 'auth/user-not-found') {
            this.notificationBar.show("Account not found!");
            this.signinGroup.reset()
            this.signinGroup.controls.emailControl.setErrors(null);
            this.signinGroup.controls.emailControl.setValue("");
            this.signinGroup.controls.passwordControl.setErrors(null);
            this.signinGroup.controls.passwordControl.setValue("");
          } 
          else {
            this.notificationBar.showUnknownError();            
          }
        })
      }
    });
  }

  signup() {
    if (this.signupGroup.valid == false) {
      return;
    }
    this.recaptchaV3Service.execute('signup').subscribe((token) => {
      if (token == "") {
        return;
      }
      else {
        this.isProcessing = true;
        var user = new SignUpUser();
        user.username = this.signupGroup.controls.usernameControl.value;
        user.email = this.signupGroup.controls.emailControl.value;
        user.password = this.signupGroup.controls.passwordControl.value;
        this.fireAuth.createUserWithEmailAndPassword(user.email, user.password).then( async res => {
          await this.getStorageFiles("profile_images").then(storageFiles => {
            this.storageFiles = storageFiles;
            this.storageFiles.sort(function(a, b) {
              return a.name.localeCompare(b.name);
           });
          });
          await res.user.updateProfile({
            displayName: user.username,
            photoURL: this.storageFiles[0].link
          })       
          this.checkVerification();
        }, err => {
          this.isProcessing = false;
          if (err.code == 'auth/weak-password') {
            this.notificationBar.show("Password is too weak!");
            this.signupGroup.controls.passwordControl.setValue("");
          } 
          else if (err.code == 'auth/email-already-in-use') {
            this.notificationBar.show("Email already assigned to an account!");
            this.signupGroup.controls.emailControl.setValue("");
          }
          else if (err.code == 'auth/invalid-email') {
            this.notificationBar.show("Email is not valid!");
            this.signupGroup.controls.emailControl.setValue("");
          }
          else if (err.code === 'auth/invalid-email') {
            this.notificationBar.show("Please enter a valid email!");
            this.signupGroup.controls.emailControl.setErrors({ notValid: true });
          }
          else if (err.code === 'auth/missing-password') {
            this.notificationBar.show("Please enter your password!");
            this.signupGroup.controls.passwordControl.setErrors({ passwordMissing: true });
          }
          else { 
            this.notificationBar.showUnknownError();
          }
        })
      }
    });
  }

  async checkVerification() {
    this.waitingOnVerify = true;
    this.isProcessing = false;
    this.signinGroup.reset()
    this.signinGroup.controls.emailControl.setErrors(null);
    this.signinGroup.controls.emailControl.setValue("");
    this.signinGroup.controls.passwordControl.setErrors(null);
    this.signinGroup.controls.passwordControl.setValue("");
    this.hidePasswordSignin = true;
    this.signupGroup.reset();
    this.signupGroup.controls.usernameControl.setErrors(null);
    this.signupGroup.controls.usernameControl.setValue("");
    this.signupGroup.controls.emailControl.setErrors(null);
    this.signupGroup.controls.emailControl.setValue("");
    this.signupGroup.controls.passwordControl.setErrors(null);
    this.signupGroup.controls.passwordControl.setValue("");
    this.signupGroup.controls.consentControl.setErrors(null);
    this.signupGroup.controls.consentControl.setValue(false);
    this.hidePasswordSignup = true;
    var tickRate = interval(2000);
    this.checkResendLock();
    var user = (await this.fireAuth.currentUser);
    user.sendEmailVerification().then(res => {
      this.notificationBar.show("Verification email sent!")
    }, err => {
      this.notificationBar.showUnknownError();
      this.waitingOnVerify = false;
      this.fireAuth.signOut();
    });
    this.emailForVerify = user.email;
    this.verificationCheckSubscription = tickRate.subscribe(async tick => {
      user.reload();
      if (user.emailVerified == true){
        this.verificationCheckSubscription.unsubscribe();
        var idToken = "";
        await user.getIdToken(true).then( res => {
          idToken = res;
        });
        this.redirectAfterAuth(idToken); 
      }
    })
  }

  checkResendLock() {
    this.lockTime = 60;
    this.lockResend = true;
    var tickRate = interval(1000);
    this.resendLockCheckSubscription = tickRate.subscribe(async tick => {
      this.lockTime--;
      if (this.lockTime == 0){
        this.resendLockCheckSubscription.unsubscribe();
        this.lockResend = false;
      }
    })
  }

  async resendVerificationEmail() {
    this.verificationCheckSubscription.unsubscribe();
    this.checkVerification();
  }
}